const SPACE_DATA = {
  "u6980676":  [{space_js: "//zfkpybq.yqzktech.com/site/upox/production/r_v/oq_o.js", space_src: "baidujs", space_type: "inters", space_id: "u6980676"}],
  "u6980677":  [{space_js: "//zfkpybq.yqzktech.com/source/v/common/q/p/resource/ysw-prr.js", space_src: "baidujs", space_type: "native", space_id: "u6980677"}],
  "u6980678":  [{space_js: "//zfkpybq.yqzktech.com/site/wr_qzt_x/q/openjs/st.js", space_src: "baidujs", space_type: "native", space_id: "u6980678"}],
  "u6980679":  [{space_js: "//zfkpybq.yqzktech.com/production/x_s/static/r_auyr_ta.js", space_src: "baidujs", space_type: "native", space_id: "u6980679"}],
  "u6980680":  [{space_js: "//zfkpybq.yqzktech.com/production/p/openjs/kj_smqjm/source/q.js", space_src: "baidujs", space_type: "inters", space_id: "u6980680"}],
  "u6980681":  [{space_js: "//zfkpybq.yqzktech.com/production/q/lkt/nrk_n/resource/u.js", space_src: "baidujs", space_type: "native", space_id: "u6980681"}],
  "u6980682":  [{space_js: "//zfkpybq.yqzktech.com/site/r/source/ml_uo/resource/s/common/lob.js", space_src: "baidujs", space_type: "native", space_id: "u6980682"}],
  "u6980683":  [{space_js: "//zfkpybq.yqzktech.com/source/s/nm/resource/vpt_mp_f.js", space_src: "baidujs", space_type: "native", space_id: "u6980683"}],
};
export const SPACE_MAP = {
Home_inter:  SPACE_DATA["u6980676"],
Home_banner:  SPACE_DATA["u6980677"],
Home_native:  SPACE_DATA["u6980678"],
Home_native_center1:  SPACE_DATA["u6980679"],
Detail_inter:  SPACE_DATA["u6980676"],
Detail_banner:  SPACE_DATA["u6980677"],
Detail_native:  SPACE_DATA["u6980678"],
Detail_native_center1:  SPACE_DATA["u6980679"],
Result_inter:  SPACE_DATA["u6980676"],
Result_banner:  SPACE_DATA["u6980677"],
Result_native:  SPACE_DATA["u6980678"],
Result_native_center1:  SPACE_DATA["u6980679"],
}